export const FEATURE_FLAG_CONFIGURATION_KEY = "FEATURE_FLAG_CONFIGURATION_KEY";
const isServer = typeof window === "undefined";

export function setFeatureFlagConfigValue(key: string, value: boolean) {
    if (!isServer) {
        const config = getFeatureFlagConfig();

        window.localStorage.setItem(FEATURE_FLAG_CONFIGURATION_KEY, JSON.stringify({
            ...config,
            [key]: value,
        }));
    }
}

export function getFeatureFlagConfig(): Record<string, boolean> {
    if (!isServer) {
        const config = window.localStorage.getItem(FEATURE_FLAG_CONFIGURATION_KEY);
        if (config) {
            try {
                return JSON.parse(config) as Record<string, boolean>;
            } catch {
                return {};
            }
        }
    }
    return {};
}

export function resetFeatureFlagConfig() {
    if (!isServer) {
        window.localStorage.removeItem(FEATURE_FLAG_CONFIGURATION_KEY);
    }
}
