<template>
    <span>
        <router-link
            :class="classes"
            :to="to"
        >
            <slot />
        </router-link>
    </span>
</template>

<script>
import { colorsList } from "../lib";
import { computed } from "vue";

export const themes = {
    Primary: {
        color: colorsList.primary,
    },
    Secondary: {
        color: colorsList.secondary,
    },
};

export default {
    name: "FeLink",
    props: {
        theme: {
            type: Object,
            default: () => {
                return themes.Secondary;
            },
        },
        to: {
            type: Object,
            default: () => {
                return {};
            },
        },
        classLink: {
            type: String,
            default: "",
        },
    },
    setup(props, { attrs, listeners }) {
        const classes = computed(() => ({
            "fe-link": true,
            [`fe-link--${ props.theme.color }`]: props.theme.color,
            [props.classLink]: true,
            [attrs.class]: attrs.class,
        }));

        return { classes, ...listeners };
    },
};
</script>

<style src="./style.scss"></style>
