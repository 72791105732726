<template>
    <div class="fe-badge" :class="`fe-badge--${theme}`" :data-test="dataTest">
        <slot/>
    </div>
</template>

<script>

export default {
    name: "FeBadge",

    props: {
        theme: {
            type: String,
            default: "primary",
        },
        dataTest: {
            type: String,
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
