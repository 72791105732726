<template>
    <div class="carousel-scroll__navigation">
        <template v-if="featureFlags.enableNewUIComponents">
            <FeButtonNew
                :icon="FeIconConfig.icons.fill.dropdownLeft"
                :theme="themes.PrimaryLarge"
                aria-label="Previous page"
                :tabindex="backward ? 0 : -1"
                class="carousel-scroll__navigation-button carousel-scroll__navigation-prev"
                :class="{ 'carousel-scroll__navigation--hidden': !backward }"
                @click="pagination(-1)" />
            <FeButtonNew
                :icon="FeIconConfig.icons.fill.dropdownRight"
                :theme="themes.PrimaryLarge"
                aria-label="Next page"
                :tabindex="forward ? 0 : -1"
                class="carousel-scroll__navigation-button carousel-scroll__navigation-next"
                :class="{ 'carousel-scroll__navigation--hidden': !forward }"
                @click="pagination(1)" />
        </template>
        <template v-else>
            <FeButton
                :icon="FeIconConfig.icons.fill.dropdownLeft"
                :theme="FeButtonConfig.themes.TertiaryMedium"
                aria-label="Previous page"
                :tabindex="backward ? 0 : -1"
                class="carousel-scroll__navigation-button carousel-scroll__navigation-prev"
                :class="{ 'carousel-scroll__navigation--hidden': !backward }"
                @click="pagination(-1)" />
            <FeButton
                :icon="FeIconConfig.icons.fill.dropdownRight"
                :theme="FeButtonConfig.themes.TertiaryMedium"
                aria-label="Next page"
                :tabindex="forward ? 0 : -1"
                class="carousel-scroll__navigation-button carousel-scroll__navigation-next"
                :class="{ 'carousel-scroll__navigation--hidden': !forward }"
                @click="pagination(1)" />
        </template>

    </div>
</template>

<script>
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import * as FeIconConfig from "@ui/FeIcon/FeIcon";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";

export default {
    name: "Navigation",
    components: {
        FeButtonNew,
        FeButton,
    },
    props: {
        backward: {
            type: Boolean,
            default: false,
        },
        forward: {
            type: Boolean,
            default: false,
        },
    },
    emits: [ "change" ],
    data() {
        return {
            FeButtonConfig,
            FeIconConfig,
        };
    },
    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
    },
    methods: {
        pagination(index) {
            this.$emit("change", index);
        },
    },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
