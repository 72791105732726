<template>
    <div
        class="fe-switch"
        :class="{
            'fe-switch--with-label': Boolean(label),
            'fe-switch--checked': value
        }"
        @click="$emit('switch:change-toggle', !value)"
    >
        <span v-if="label" class="fe-switch__label">{{ label }}</span>
        <span class="fe-switch__checkmark" />
    </div>
</template>

<script>
export default {
    name: "FeSwitch",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
        },
    },
    emits: [ "switch:change-toggle" ],
};
</script>

<style lang="scss">
@import "~@theme/styles";

.fe-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    user-select: none;
    @include font-size--Caption1($font-weight--normal);
    margin: 0;
    padding: 0;

    &--with-label {
        width: 100%;
        padding: .5rem 1rem;
        border-radius: var(--border-radius-s);
        background: var(--color-layer-alt-1);
    }

    &__checkmark {
        position: relative;
        display: inline-block;
        height: 0.875rem;
        width: 2rem;
        background-color: var(--color-layer-alt-3);
        border-radius: 3rem;
        transition: background-color 0.2s;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: -3px;
            height: 1.25rem;
            width: 1.25rem;
            background-color: var(--color-layer-alt-2);
            border-radius: 50%;
            border: 1px solid var(--color-tertiary-3);
            transition: all 0.2s;
            box-sizing: border-box;
        }
    }

    &--checked .fe-switch__checkmark {
        background-color: var(--color-layer-alt-3);

        &:after {
            transform: translateX(1rem);
            background: var(--color-primary-gradient);
            border: none;
        }
    }
}
</style>
