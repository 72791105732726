<template>
    <div class="fe-input-wrapper">
        <div
            class="fe-input"
            :class="mainClasses"
        >
            <div v-if="label" class="fe-input__label" :class="labelClasses">
                {{ label }}
            </div>
            <input
                ref="input"
                class="fe-input__field"
                :autocomplete="autocomplete"
                :autofocus="autofocus"
                :data-test="dataTest"
                :disabled="disabled"
                :max="max"
                :maxlength="maxlength"
                :min="min"
                :name="name"
                :pattern="pattern"
                :placeholder="placeholder"
                :step="step"
                :type="type"
                :value="modelValue"
                :inputmode="inputmode"
                @change="changeHandler"
                @input="inputHandler"
                @focus="focusHandler"
                @blur="blurHandler"
            >
            <div v-if="$slots.right" class="fe-input__right">
                <slot name="right" />
            </div>
        </div>
        <transition name="slide">
            <div v-if="!!errorMessages" class="fe-input-error">
                <FeIcon
                    :icon="icons.fill.attention"
                    class="fe-input-error__icon"
                />
                <span class="fe-input-error__text">{{ errorMessages }}</span>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon.vue";
import type { IBaseFieldProps } from "@components/FormNew/BaseField";
import { baseFieldDefault } from "@components/FormNew/BaseField";

const emit = defineEmits([ "update:modelValue", "input:focus", "input:blur", "input:change" ]);

const props = withDefaults(defineProps<IBaseFieldProps>(), {
    ...baseFieldDefault,
});

const input = ref(null);
const focused = ref(false);

const mainClasses = computed(() => {
    return {
        "fe-input--has-content": Boolean(props.modelValue),
        "fe-input--focused": focused.value,
        "fe-input--error": props.errorMessages,
        "fe-input--disabled": props.disabled,
    };
});

const labelClasses = computed(() => {
    return {
        "fe-input__label--required": props.required,
    };
});

function inputHandler($event) {
    emit("update:modelValue", $event.target.value);
}
function focusHandler() {
    focused.value = true;
    emit("input:focus", true);
}
function blurHandler() {
    focused.value = false;
    emit("input:blur", false);
}
function changeHandler($event) {
    emit("input:change", $event.target.value);
}
</script>

<style lang="scss">
.fe-input {
    align-items: center;
    background: var(--color-layer-alt-4);
    border-radius: var(--border-radius-s);
    border: 2px solid var(--color-layer-alt-4);
    box-sizing: border-box;
    display: flex;
    height: 3.5rem;
    overflow: hidden;
    position: relative;
    transition: 0.15s;
    width: 100%;

    &--focused {
        border-color: var(--color-primary-1);
        cursor: text;
    }

    &--error {
        border: 2px solid var(--color-error);
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &__label {
        align-items: center;
        color: var(--color-text-body);
        display: flex;
        font-size: 1rem;
        height: 100%;
        left: 1rem;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: 0.15s;
        z-index: 1;

        &--required::after {
            color: var(--color-error);
            content: "*";
        }
    }
    &.fe-input--has-content &__label,
    &.fe-input--focused &__label {
        font-size: 0.75rem;
        height: 50%;
        padding-top: 0.25rem;
    }

    &__field {
        background: transparent;
        border: none;
        color: var(--color-text-alt);
        font-size: 1rem;
        height: 100%;
        margin: auto 0 0;
        opacity: 0;
        outline: none;
        padding: 0 1rem 0.5rem;
        position: relative;
        transition: 0.15s;
        width: 100%;

        &::placeholder {
            color: var(--color-text-body);
            letter-spacing: normal;
            opacity: 1;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active,
        &:autofill {
            -webkit-text-fill-color: var(--color-text-alt);
            background-color: var(--color-layer-alt-4);
            background-clip: content-box;
            transition: background-color 5000s ease-in-out 0s;
        }

        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
            pointer-events: none;
            position: absolute;
            right: 0;
        }
    }
    &.fe-input--has-content &__field,
    &.fe-input--focused &__field {
        padding-top: 1.7rem;
        opacity: 1;
    }

    &__right {
        flex-shrink: 0;
    }
}

.fe-input-error {
    align-items: flex-start;
    color: var(--color-error);
    display: flex;
    font-size: 0.75rem;
    padding-left: 0.75rem;
    line-height: 1.1;
    margin-top: 0.5rem;

    &__icon {
        display: flex;
        flex-shrink: 0;
        height: 0.75rem;
        margin-right: 0.25rem;
        width: 0.75rem;
    }
}
</style>
