<template>
    <div
        class="fe-tab"
        :class="classes"
        tabindex="0"
        @click="clickHandler"
    >
        <span ref="tabInfo">
            {{ t(tabTitle) }}
        </span>
    </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref, computed, onMounted } from "vue";

const { t } = useI18n();
const tabInfo = ref(null);

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    active: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    tabTitle: {
        type: String,
        default: "",
    },
});

const emit = defineEmits([ "setActiveTab", "setTabOptions" ]);

const classes = computed(() => {
    return {
        "fe-tab--active": props.active,
        "fe-tab--disabled": props.disabled,
    };
});

const elTabOptions = () => {
    const parentEl = tabInfo.value.parentElement;
    const leftParent = parentEl.offsetLeft;
    const widthParent = parentEl.offsetWidth;
    const width = tabInfo.value.offsetWidth;

    const offsetLeft = leftParent + (widthParent - tabInfo.value.offsetLeft - width);

    return {
        width,
        offsetLeft,
    };
};

onMounted(() => {
    emit("setTabOptions", elTabOptions());
});

const clickHandler = () => {
    if (props.disabled) {
        return;
    }
    emit("setActiveTab", elTabOptions());
};


</script>

<style lang="scss" src="./style.scss"></style>
