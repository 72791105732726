<template>
    <img
        :class="classes"
        :src="src"
        :srcset="srcset"
        :alt="alt"
        :loading="loading"
        v-bind="$attrs"
    >
</template>

<script setup lang="ts">
import { computed, useAttrs } from "vue";
import { useEnvironments } from "@store/environments";

interface IProps {
    src: string;
    srcRetina?: string;
    alt?: string;
    type?: string;
};

const props = defineProps<IProps>();

const attrs = useAttrs();

const srcset = computed(() => {
    if (props.srcRetina) {
        return `${props.src} 1x, ${props.srcRetina} 2x`;
    }
});

const classes = computed(() => {
    let res = attrs.class || "";
    if (Array.isArray(attrs.class)) {
        res = attrs.class.join(" ");
    } else if (typeof attrs.class === "object") {
        res = Object.keys(attrs.class).filter((key) => attrs.class[key]).join(" ");
    }
    return res;
});

const { useMocker } = useEnvironments();

const loading = computed(() => useMocker ? "eager" : "lazy");

</script>

<style lang="scss">
object {
    pointer-events: none;
}
</style>
