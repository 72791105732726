<template>
    <span class="fe-icon" v-bind="$attrs" v-html="icon"/>
</template>

<script>
export { icons } from "@theme/configs/icons";

export default {
    name: "FeIcon",

    props: {
        icon: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import "~@theme/styles";

.fe-icon {
    display: block;
    @include transition(
            transition--color(),
            transition--border-color(),
            transition--background-color()
    );

    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    svg path {
        fill: currentColor;
    }
}
</style>
