/**
 * @typedef {String} Size
 */

import { colorsList } from "@ui/lib";

/**
 * @cons
 * @typedef {Object} SizeList
 * @enum {Size}
 */
export enum sizeList {
    small = "small",
    medium = "medium",
    large = "large"
}

/**
 * @cons
 * @typedef {Object} SizeShadowList
 * @enum {Size}
 */
export const sizeShadowList = {
    small: "small",
    normal: "normal",
    medium: "medium",
};

export interface IButtonTheme {
    color: colorsList;
    size: sizeList;
}

/**
 * @typedef {Object} Theme
 * @property {Color} color
 * @property {Size} size
 */

/**
 * @cons
 * @typedef {Object} ThemesList
 * @enum {Theme}
 */
export const themes: Record<string, IButtonTheme> = {
    PrimaryLarge: {
        color: colorsList.primary,
        size: sizeList.large,
    },
    PrimaryMedium: {
        color: colorsList.primary,
        size: sizeList.medium,
    },
    PrimarySmall: {
        color: colorsList.primary,
        size: sizeList.small,
    },
    SecondaryLarge: {
        color: colorsList.secondary,
        size: sizeList.large,
    },
    SecondaryMedium: {
        color: colorsList.secondary,
        size: sizeList.medium,
    },
    SecondarySmall: {
        color: colorsList.secondary,
        size: sizeList.small,
    },
    TertiaryLarge: {
        color: colorsList.tertiary,
        size: sizeList.large,
    },
    TertiaryMedium: {
        color: colorsList.tertiary,
        size: sizeList.medium,
    },
    TertiarySmall: {
        color: colorsList.tertiary,
        size: sizeList.small,
    },
    GhostLarge: {
        color: colorsList.ghost,
        size: sizeList.large,
    },
    GhostMedium: {
        color: colorsList.ghost,
        size: sizeList.medium,
    },
    GhostSmall: {
        color: colorsList.ghost,
        size: sizeList.small,
    },
    SlabMedium: {
        color: colorsList.slab,
        size: sizeList.medium,
    }
};
