<template>
    <div
        class="fe-checkbox"
        :class="{'fe-checkbox--error': errorMessages}"
    >
        <div class="fe-checkbox__container">
            <label
                :class="className"
                :data-test="dataTestAttr"
                class="fe-checkbox__label"
            >
                <input
                    :value="value"
                    :checked="value"
                    type="checkbox"
                    class="fe-checkbox__input"
                    @change="$emit('input:change-checkbox', $event.target.checked)"
                >
                <span class="fe-checkbox__mask"/>
            </label>
            <slot/>
        </div>
        <span v-if="errorMessages" class="fe-checkbox__error-message">{{ errorMessages }}</span>
    </div>
</template>

<script>
export default {
    name: "FeCheckbox",
    props: {
        className: {
            type: String,
        },
        value: {
            type: Boolean,
        },
        dataTestAttr: {
            type: String,
        },
        errorMessages: {
            type: String,
        },
    },
    emits: [ "input:change-checkbox" ],
};
</script>

<style lang="scss" src="./style.scss"></style>
