<template>
    <span
        class="fe-nav-badge"
        :class="classes"
    >
        {{ badge.text }}
    </span>
</template>

<script setup lang="ts">
import type { IBadge } from "@helpers/getActiveClassNavMenu";
import { computed } from "vue";

interface Props {
    badge: IBadge;
}

const props = defineProps<Props>();
const classes = computed<string[]>(() => {
    return [
        `fe-nav-badge--${ props.badge.background }`,
        `fe-nav-badge--${ props.badge.color }`,
    ];
});
</script>

<style lang="scss" src="./style.scss"></style>
