import log from "@controllers/Logger";

/* eslint-disable padded-blocks */
export class TabType {

    /**
     * Create a tab
     * @param {String} name - Tab key name.
     * @param {String} title - Tab title.
     */
    constructor(name = "", title = "") {
        this._name = name;
        this._title = title || this.name;
    }

    get name() {
        return this._name;
    }

    get title() {
        return this._title;
    }
}

export class TabsType {

    /**
     * Create a tabs list
     * @param {String} name - tabs name
     * @param {TabType[]} list - tabs list
     * @param {TabType} [active] - active tab
     */
    constructor(name, list, active) {
        this._name = name;

        if (!list || !Array.isArray(list)) {
            log.error("TABS_CREATE", new Error("List of tabs has be array"));
        }

        if (active && !(active instanceof TabType)) {
            log.error("TABS_CREATE", new Error("Active tab has be instance of Tab class"));
        }

        this._list = list;
        if (active) {
            this._active = active;
        } else {
            [ this._active ] = this.list;
        }
    }

    /**
     * Return tabs name
     * @returns {string} Tabs name
     */
    get name() {
        return this._name;
    }

    /**
     * Return tabs list
     * @returns {TabType[]} Tabs list
     */
    get list() {
        return this._list;
    }

    /**
     * Return active tab.
     * @returns {TabType} Active tab
     */
    get active() {
        return this._active;
    }


    /**
     * Set active tab
     * @param {TabType} tab - new active tab
     * */
    set active(tab) {
        this._active = tab;
    }
}
