export interface IBaseFieldProps {
    autocomplete?: string;
    autofocus?: boolean;
    dataTest: string;
    disabled?: boolean;
    errorMessages?: string;
    inputmode?: string;
    label?: string;
    max?: number | string;
    maxlength?: number;
    min?: number | string;
    modelValue: string | number;
    name: string;
    pattern?: string;
    placeholder?: string;
    required?: boolean;
    rules?: string | string[] | object;
    step?: number;
    type?: string;
}

export const baseFieldDefault = {
    autofocus: false,
    disabled: false,
    errorMessages: "",
    inputmode: "text",
    placeholder: "",
    required: false,
    type: "text",
};
