<template>
    <render/>
</template>

<script setup lang="ts">
import {
    nextTick,
    onMounted,
    onUpdated,
    onUnmounted,
    ref,
    watch,
    type VNode,
    h,
    useSlots,
    Fragment,
} from "vue";
import Navigation from "./Navigation.vue";
import Pagination from "./Pagination.vue";

interface ICarouselScrollProps {
    activeIndex?: number;
    autoFill?: boolean;
    autoplay?: number;
    pagination?: boolean;
    showNavigation?: boolean;
    transparentBorder?: boolean;
}

interface ICarouselScrollEmits {
    (event: "change-page", value: number): void;
    (event: "view-banner", value: number): void;
}

const props = withDefaults(defineProps<ICarouselScrollProps>(), {
    activeIndex: 0,
    autoFill: false,
    pagination: false,
    showNavigation: true,
    transparentBorder: false,
});
const emit = defineEmits<ICarouselScrollEmits>();

const carouselList = ref<HTMLDivElement | null>(null);
const localActiveIndex = ref(props.activeIndex);
const autoPlayId = ref<number | null>(null);
const backward = ref(false);
const forward = ref(false);

const getItemWidth = () => {
    if (!carouselList.value) {
        return 0;
    }

    const style = getComputedStyle(carouselList.value);
    const gap = parseFloat(style.columnGap);
    const { firstElementChild } = carouselList.value;

    if (!firstElementChild) {
        return 0;
    }

    // @ts-expect-error Property `offsetWidth` does not exist on type `Element`
    return firstElementChild.offsetWidth + gap;
};

const updateScrollPosition = (index: number) => {
    if (!carouselList.value) {
        return;
    }

    const { offsetWidth } = carouselList.value;
    const itemWidth = getItemWidth();

    const perPage = Math.floor(offsetWidth / itemWidth);
    const slideWidth = perPage * itemWidth;

    carouselList.value.scrollTo({
        left: slideWidth * index,
        behavior: "smooth",
    });
};

const setPosition = () => {
    if (props.activeIndex === 0) {
        return;
    }

    const offsetToActivePosition = props.activeIndex * getItemWidth();
    carouselList.value?.scrollTo({
        left: offsetToActivePosition,
    });
};

const getCurrentPage = () => {
    if (!carouselList.value) {
        return 0;
    }

    const { scrollLeft, offsetWidth } = carouselList.value;
    const itemWidth = getItemWidth();
    const perPage = Math.floor(offsetWidth / itemWidth);
    const slideWidth = perPage * itemWidth;

    return Math.round(scrollLeft / slideWidth);
};
const calculateCarousel = (element: HTMLElement | null) => {
    if (!element) {
        return;
    }

    const { scrollLeft, offsetWidth, scrollWidth } = element;
    const currentScrollWidth = scrollLeft + offsetWidth;

    backward.value = scrollLeft > 0;
    forward.value = currentScrollWidth < scrollWidth;
    localActiveIndex.value = getCurrentPage();
};

const handleScroll = (element: Event) => {
    calculateCarousel(element.target as HTMLElement);
};

const setAutoPlay = () => {
    if (props.autoplay && autoPlayId.value === null) {
        autoPlayId.value = setInterval(() => {
            localActiveIndex.value = getCurrentPage() + 1;
            if (carouselList.value && localActiveIndex.value >= carouselList.value.children.length) {
                localActiveIndex.value = 0;
            }

            updateScrollPosition(localActiveIndex.value);
        }, props.autoplay);
    }
};

const stopTimer = () => {
    if (autoPlayId.value !== null) {
        clearInterval(autoPlayId.value);
        autoPlayId.value = null;
    }
};

watch(
    () => props.activeIndex,
    (value) => {
        localActiveIndex.value = value;
        setPosition();
    },
);
watch(
    () => localActiveIndex.value,
    (value) => {
        emit("change-page", value);
    },
);

function viewBanner() {
    emit("view-banner", localActiveIndex.value);
}

onMounted(() => {
    nextTick(() => {
        setPosition();
        calculateCarousel(carouselList.value);
    });

    setAutoPlay();

    viewBanner();
    carouselList.value?.addEventListener("scrollend", viewBanner);
});
onUpdated(() => {
    calculateCarousel(carouselList.value);
});
onUnmounted(() => {
    stopTimer();
    carouselList.value?.removeEventListener("scrollend", viewBanner);
});

const slots = useSlots();

function render() {
    const _slotDefault: VNode[] = slots.default ? slots.default() : [];

    // type any because there is hell in checking types
    // this code just unpack fragment (<template> wrappers) in slot
    function getChildren(nodes: any[]): any[] {
        if (!Array.isArray(nodes)) {
            return nodes;
        }
        return nodes.reduce((result, node) => {
            if (typeof node === "object" && !Array.isArray(node) && node?.type === Fragment) {
                return [ ...result, ...getChildren(node.children) ];
            }
            return [ ...result, node ];
        }, []);
    }

    const _slotChildren = getChildren(_slotDefault);

    const children = Array.isArray(_slotChildren)
        ? _slotChildren?.map((element, i, arr) => {
            return h(
                "div",
                {
                    class: {
                        "carousel-scroll__item": true,
                        "last-child": i === arr.length - 1,
                    },
                },
                element as VNode,
            );
        })
        : [];

    return h(
        "div",
        {
            class: {
                "carousel-scroll": true,
                "carousel-scroll--transparent-border": props.transparentBorder,
                "carousel-scroll--auto-fill": props.autoFill,
            },
            onMouseover: stopTimer,
            onMouseleave: setAutoPlay,
        },
        [
            props.showNavigation
                ? h(Navigation, {
                    backward: backward.value,
                    forward: forward.value,
                    onChange: (direction: number) => updateScrollPosition(localActiveIndex.value + direction),
                })
                : [],
            h(
                "div",
                {
                    class: "carousel-scroll__list",
                    ref: (el) => (carouselList.value = el as HTMLDivElement),
                    onScroll: handleScroll,
                },
                [ children ],
            ),
            props.pagination
                ? h(Pagination, {
                    length: children?.length,
                    activeIndex: localActiveIndex.value,
                    onChange: (index: number) => updateScrollPosition(index),
                })
                : null,
        ],
    );
}
</script>

<style lang="scss">
@import "~@theme/styles";

/**
    * Global default properties
*/
:root {
    --carousel-item-width: 12rem;
    --carousel-gap: 1rem;

    @include media(M) {
        --carousel-gap: 1.5rem;
    }
}
</style>

<style lang="scss" scoped src="./style.scss"></style>
