<template>
    <button
        class="fe-button"
        :type="buttonType"
        :class="classes"
        :disabled="disabled || waiting"
        @click="clickHandler"
    >
        <FeIcon v-if="waiting" :icon="icons.outline.loader" class="fe-button__icon fe-button__loading"/>
        <FeIcon v-else-if="icon && !iconLeft" class="fe-button__icon" :icon="icon"/>
        <span v-if="$slots.default" class="fe-button__title"><slot/></span>
        <span v-if="$slots.right" class="fe-button__right"><slot name="right"/></span>
    </button>
</template>

<script>
import { colorsList } from "@ui/lib.js";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";

/**
 * @typedef {String} Size
 * */

/**
 * @cons
 * @typedef {Object} SizeList
 * @enum {Size}
 * */
const sizeList = {
    extraSmall: "extra-small",
    small: "small",
    medium: "medium",
};

/**
 * @cons
 * @typedef {Object} SizeShadowList
 * @enum {Size}
 * */
export const sizeShadowList = {
    small: "small",
    normal: "normal",
    medium: "medium",
};

/**
 * @typedef {Object} Theme
 * @property {Color} color
 * @property {Size} size
 * */

/**
 * @cons
 * @typedef {Object} ThemesList
 * @enum {Theme}
 * */
export const themes = {
    PrimaryMedium: {
        color: colorsList.primary,
        size: sizeList.medium,
    },
    PrimarySmall: {
        color: colorsList.primary,
        size: sizeList.small,
    },
    PrimaryExtraSmall: {
        color: colorsList.primary,
        size: sizeList.extraSmall,
    },
    SecondaryMedium: {
        color: colorsList.secondary,
        size: sizeList.medium,
    },
    SecondarySmall: {
        color: colorsList.secondary,
        size: sizeList.small,
    },
    SecondaryExtraSmall: {
        color: colorsList.secondary,
        size: sizeList.extraSmall,
    },
    TertiaryMedium: {
        color: colorsList.tertiary,
        size: sizeList.medium,
    },
    TertiarySmall: {
        color: colorsList.tertiary,
        size: sizeList.small,
    },
    TertiaryExtraSmall: {
        color: colorsList.tertiary,
        size: sizeList.extraSmall,
    },
    GhostMedium: {
        color: colorsList.ghost,
        size: sizeList.medium,
    },
    GhostSmall: {
        color: colorsList.ghost,
        size: sizeList.small,
    },
};

export default {
    name: "FeButton",

    components: {
        FeIcon,
    },

    props: {


        theme: {
            type: Object,
            default: () => {
                return themes.PrimarySmall;
            },
        },

        /**
         * Force 100% of the button width
         * */
        fullWidth: {
            type: Boolean,
            default: false,
        },

        /**
         * Force 100% of the button width
         * */
        fullWidthMobile: {
            type: Boolean,
            default: false,
        },

        /**
         * @type Boolean
         * @default false
         * */
        uppercase: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: [ Boolean, String ],
            default: false,
        },
        textAlign: {
            type: String,
            default: "",
        },
        waiting: {
            type: Boolean,
            default: false,
        },
        shadowSize: {
            type: String,
            default: sizeShadowList.small,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * @type Boolean
         * @default false
         * */
        submit: {
            type: Boolean,
            default: false,
        },

        /**
         * @type Boolean
         * @default false
         * */
        iconLeft: {
            type: Boolean,
            default: false,
        },
    },
    emits: [ "click" ],

    setup(_, { slots }) {
        return { slots };
    },

    data() {
        return {
            icons,
        };
    },

    computed: {

        /**
         * List of classes for button
         *
         * @returns {Object}
         * */
        classes() {
            return {
                [`fe-button--${ this.theme.color }`]: this.theme.color,
                [`fe-button--${ this.theme.size }`]: this.theme.size,
                [`fe-button--text-${ this.theme.textAlign }`]: this.theme.textAlign,
                [`fe-button--text-${ this.textAlign }`]: this.textAlign,
                [`fe-button--shadow-${ this.shadowSize }`]: this.shadowSize && this.shadow,
                "fe-button--uppercase": this.uppercase || this.theme.uppercase,
                "fe-button--waiting": this.waiting,
                "fe-button--icon": this.icon && !this.slots.default,
                "fe-button--disabled": this.disabled,
                "fe-button--full-width": this.fullWidth,
                "fe-button--full-width-mobile": this.fullWidthMobile,
            };
        },

        buttonType() {
            return this.submit ? "submit" : "button";
        },
    },

    methods: {

        /**
         * Click event
         *
         * @param {Object} $event - Mouse click events
         * @event click
         * */
        clickHandler($event) {
            if (this.disabled) {
                return;
            }
            this.$emit("click", $event);
        },
    },

};
</script>

<style lang="scss">
@import "~@theme/styles";

$fe-button--border-radius: 2rem;
$fe-button--text-transform: uppercase;
$fe-button--secondary--color: var(--color-button-text-secondary);
$fe-button--tertiary--color: var(--color-tertiary-1);
$fe-button--small--padding: 0.5rem 1rem;

.fe-button {
    white-space: nowrap;
    border: none;
    color: var(--color-layer-alt-1);
    @include font-family--secondary;
    @include btn-disabled;
}


@mixin shadow-button($color) {
    $sizes: "small", "normal", "medium";

    @each $size in $sizes {
        &-#{$size} {
            @include shadow($size, $color);
        }
    }
}

.fe-button {
    font-family: $font-family--secondary;
    // @include font-size--main;
    font-weight: $font-weight--bold;
    @include transition(
            transition--box-shadow(),
            transition--color(),
            transition--background-color()
    );
    text-align: center;
    border-radius: $fe-button--border-radius;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    text-transform: $fe-button--text-transform;

    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;

    // --Color--
    &--primary {
        @include background-primary;

        &:hover {
            background: linear-gradient(270deg, var(--color-primary-3), var(--color-primary-3) 100%);
        }

        &:active {
            background: linear-gradient(270deg, var(--color-primary-4), var(--color-primary-4) 100%);
        }
    }

    &--primary#{&}--disabled {
        //@include background-disabled;
        @include font-color--disabled;
    }

    &--secondary {
        color: $fe-button--secondary--color;
        background: var(--color-tertiary-3);
        backdrop-filter: blur(var(--filter-blur-xs));

        &:hover {
            background: var(--color-tertiary-2);
            color: $fe-button--secondary--color;
        }

        &:active {
            background: var(--color-tertiary-4);
            color: $fe-button--secondary--color;
        }
    }

    &--tertiary {
        color: $fe-button--tertiary--color;
        background: var(--color-secondary-1);

        &:hover {
            background: var(--color-secondary-3);
            color: $fe-button--tertiary--color;
        }

        &:active {
            background: var(--color-secondary-4);
            color: $fe-button--tertiary--color;
        }
    }

    &--icon#{&}--ghost {
        color: var(--color-text-body);
        background-color: transparent;

        &:hover {
            background-color: var(--color-tertiary-2);
            color: var(--color-text-body);
        }

        &:active {
            background-color: var(--color-tertiary-4);
            color: var(--color-text-body);
        }
    }

    &--secondary#{&}--disabled {
        @include font-color--disabled;
    }

    &--secondary#{&}--shadow {
        @include shadow-button(var(--color-layer-alt-4));
    }

    // --Size--
    &--extra-small {
        @include font-size--ButtonStyleXS;
        padding: $fe-button--small--padding;
        border-radius: $fe-button--border-radius;
        height: 2rem;
    }

    &--small {
        @include font-size--ButtonStyleSmall;
        font-weight: $font-weight--bold;
        padding: $fe-button--small--padding;
        border-radius: $fe-button--border-radius;
    }

    &--medium {
        @include font-size--ButtonStyleMedium;
        padding: 0.5rem 1.5rem;
        border-radius: $fe-button--border-radius;
    }

    &__icon {
        line-height: 100%;
    }

    &__loading {
        width: 1rem;
        height: 1rem;
        animation: rotating 2s linear infinite;
        margin: 0 .5rem 0 0;
    }

    &--small #{&}__loading {
        width: 1.5rem;
        height: 1.5rem;
    }

    &--medium #{&}__loading {
        width: 1.5rem;
        height: 1.5rem;
    }

    &--icon {
        border-radius: 50%;
        padding: 0;
    }

    &--extra-small &__icon {
        width: 1rem;
        height: 1rem;
    }

    &--icon#{&}--medium {
        width: 3rem;
        height: 3rem;
    }

    &--icon#{&}--small {
        width: 2.5rem;
        height: 2.5rem;
    }

    // --Modifiers--
    &--disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--text-left {
        text-align: left;
    }

    &--text-right {
        text-align: right;
    }

    &--full-width {
        width: 100%;
        justify-content: center;
    }


    &__right {
        margin-left: .5rem;
    }
}

.fe-button--full-width-mobile {
    width: 100%;
    justify-content: center;

    @include media(M) {
        width: auto;
    }
}

</style>
