<template>
    <component :is="size.tag" :class="classNames">
        <div v-if="html" v-html="html" />
        <slot />
    </component>
</template>

<script>
import { computed } from "vue";
import { colorsList } from "@ui/lib.js";


/**
 * @typedef {Object} ThemesList
 * @enum {Theme}
 * */
export const themes = {
    White: {
        color: colorsList.white,
    },
    Black: {
        color: colorsList.black,
    },
    Disabled: {
        color: colorsList.disabled,
    },
    Primary: {
        color: colorsList.primary,
    },
    Secondary: {
        color: colorsList.secondary,
    },
    Success: {
        color: colorsList.success,
    },
    Alert: {
        color: colorsList.alert,
    },
    Danger: {
        color: colorsList.danger,
    },
    Gray: {
        color: colorsList.gray,
    },
};


/**
 * @typedef {Object} Size
 * @property {HTMLElementTagNameMap} tag
 * @property {String} [classPrefix]
 * */

/**
 * @typedef {Object} SizeList
 * @enum {Size}
 * */
export const size = {
    H1: {
        tag: "p",
        classPrefix: "h1",
    },
    H2: {
        tag: "p",
        classPrefix: "h2",
    },
    H3: {
        tag: "p",
        classPrefix: "h3",
    },
    H4: {
        tag: "p",
        classPrefix: "h4",
    },
    Base: {
        tag: "p",
    },
    BaseRegular: {
        tag: "p",
        classPrefix: "regular",
    },
    Inline: {
        tag: "span",
    },
    Small: {
        tag: "small",
        classPrefix: "small",
    },
    SmallRegular: {
        tag: "small",
        classPrefix: "small-regular",
    },
};


/**
 * @typedef {Object} AlignList
 * @enum {Align}
 * */
export const align = {
    Left: "left",
    Center: "center",
    Right: "right",
};

export default {
    name: "FeText",
    props: {
        theme: {
            type: Object,
            default: () => ({}),
        },
        align: {
            type: String,
        },
        size: {
            type: Object,
            default: () => size.Base,
        },
        html: {
            type: String,
        },
    },
    setup(props) {
        const classNames = computed(() => ({
            "fe-text": true,
            [`fe-text--${ props.theme.color }`]: props.theme.color,
            [`fe-text--${ props.size.classPrefix }`]: props.size.classPrefix,
            [`fe-text--text-${ props.align }`]: props.align,
            [props.size.classPrefix]: props.size.classPrefix,
        }));

        return { classNames };
    },
};
</script>


<style lang="scss" src="./style.scss"></style>
